package com.aldisued.foundation.mobilewebjsbridge

import com.aldisued.foundation.mobilewebjsbridge.dtos.GetImageDTOIn
import com.aldisued.foundation.mobilewebjsbridge.dtos.GetImageDTOOut
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.reflect.KClass

@Serializable
sealed interface EJsBridgeNativeApi {

    val mDtoOutClass: KClass<out Any>

    @Serializable
    @SerialName("GetImage")
    data class GetImage(
        val mDtoIn: GetImageDTOIn,
    ) : EJsBridgeNativeApi {
        override val mDtoOutClass = GetImageDTOOut::class
    }
}