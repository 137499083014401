@file:OptIn(ExperimentalJsExport::class)

package com.aldisued.foundation.mobilewebjsbridge

import com.aldisued.foundation.mobilewebjsbridge.dtos.ErrorDTOOut
import com.aldisued.foundation.mobilewebjsbridge.dtos.GetImageDTOIn
import com.aldisued.foundation.mobilewebjsbridge.dtos.GetImageDTOOut
import com.aldisued.foundation.mobilewebjsbridge.exceptions.UnknownException
import com.aldisued.foundation.mobilewebjsbridge.exceptions.UserCancelledTakingAnImageException
import com.aldisued.foundation.mobilewebjsbridge.extensions.decodeViaStringify
import kotlinx.browser.window
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlin.js.Promise


@JsExport
class JsBridge() {
    // TODO: use INativeBridge interface again in the future - right know I'm (Alex) are getting build errors when using the interface
    private val mNativeBridge by lazy {
        window.asDynamic().Android
    }

    private val mResponseCallbacks = mutableMapOf<String, PromiseCallbacks>()

    private fun callNativeApi(
        api: EJsBridgeNativeApi,
    ): Promise<dynamic> = Promise(
        executor = { resolve, reject ->
            val nativeBridge = mNativeBridge
            val jsonString = Json.encodeToString(
                api,
            )

            val requestId = nativeBridge.request(jsonString)
            mResponseCallbacks[requestId] = PromiseCallbacks(
                resolve,
                reject,
            )
        }
    )

    @JsName("onResponse")
    fun onResponse(requestId: String, dtoOut: dynamic) {
        val responseCallback =
            mResponseCallbacks.remove(requestId) ?: throw UnhandledResponseException()
        responseCallback.resolve(dtoOut)
    }

    @JsName("onResponseError")
    fun onResponseError(requestId: String, dtoOut: dynamic) {
        val responseCallback =
            mResponseCallbacks.remove(requestId) ?: throw UnhandledResponseException()
        val errorDTOOut = Json.decodeViaStringify<ErrorDTOOut>(dtoOut)
        val throwable = when (errorDTOOut.mCode) {
            ErrorDTOOut.ERROR_CODE_USER_CANCELLED_TAKING_IMAGE ->
                UserCancelledTakingAnImageException(errorDTOOut.mMessage)

            ErrorDTOOut.ERROR_CODE_UNKNOWN ->
                UnknownException(errorDTOOut.mMessage)

            else ->
                UnknownException(errorDTOOut.mMessage)
        }
        responseCallback.reject(throwable)
    }

    @JsName("getImage")
    fun getImage() = callNativeApi(
        EJsBridgeNativeApi.GetImage(
            GetImageDTOIn(
                mMaximumFileSizeInBytes = 5 * 1024 * 1024
            )
        )
    ).then<dynamic, GetImageDTOOut> {
        Json.decodeViaStringify<GetImageDTOOut>(it)
    }
}


@JsExport
fun createJsBridge() = JsBridge()