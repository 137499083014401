@file:OptIn(ExperimentalJsExport::class)

package com.aldisued.foundation.mobilewebjsbridge.dtos

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

@JsExport
@Serializable
data class GetImageDTOOut(
    @SerialName("base64Image")
    val mBase64Image: String,
)